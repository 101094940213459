import Layout from "@/layout";

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    name: "Redirect",
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () =>
          import(/* webpackChunkName: "Redirect" */ "@/views/redirect"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/login"),
    hidden: true,
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/register"),
    hidden: true,
  },
  {
    path: "/404",
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "Error404" */ "@/views/error/404"),
    hidden: true,
  },
  {
    path: "/401",
    name: "Error401",
    component: () =>
      import(/* webpackChunkName: "Error401" */ "@/views/error/401"),
    hidden: true,
  },
  {
    path: "/test",
    name: "testView",
    component: () =>
      import(/* webpackChunkName: "TestView" */ "@/views/common/TestView"),
    hidden: true,
  },
  {
    path: "/user",
    component: Layout,
    hidden: true,
    redirect: "noredirect",
    children: [
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/system/user/profile/index"
          ),
        name: "Profile",
        meta: { title: "个人中心", icon: "user" },
      },
    ],
  },
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: "/system/user-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:user:edit"],
    children: [
      {
        path: "role/:userId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "AuthRole" */ "@/views/system/user/authRole"
          ),
        name: "AuthRole",
        meta: { title: "分配角色", activeMenu: "/system/user" },
      },
    ],
  },
  {
    path: "/system/role-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:role:edit"],
    children: [
      {
        path: "user/:roleId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "AuthUser" */ "@/views/system/role/authUser"
          ),
        name: "AuthUser",
        meta: { title: "分配用户", activeMenu: "/system/role" },
      },
    ],
  },
  {
    path: "/monitor/job-log",
    component: Layout,
    hidden: true,
    permissions: ["monitor:job:list"],
    children: [
      {
        path: "index/:jobId(\\d+)",
        component: () =>
          import(/* webpackChunkName: "JobLog" */ "@/views/monitor/job/log"),
        name: "JobLog",
        meta: { title: "调度日志", activeMenu: "/monitor/job" },
      },
    ],
  },
  {
    path: "/tool/gen-edit",
    component: Layout,
    hidden: true,
    permissions: ["tool:gen:edit"],
    children: [
      {
        path: "index/:tableId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "GenEdit" */ "@/views/tool/gen/editTable"
          ),
        name: "GenEdit",
        meta: { title: "修改生成配置", activeMenu: "/tool/gen" },
      },
    ],
  },
];
