import profile from "@/assets/images/profile.png";

const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  dict: (state) => state.dict.dict,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => {
    return state.user.avatar ?? profile;
  },
  userId: (state) => state.user.userId,
  name: (state) => state.user.name,
  shopId: (state) => state.user.shopId,
  shopName: (state) => state.user.shopName,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  topbarRouters: (state) => state.permission.topbarRouters,
  defaultRoutes: (state) => state.permission.defaultRoutes,
  sidebarRouters: (state) => state.permission.sidebarRouters,
};
export default getters;
