import Vue from "vue";
import Router from "vue-router";
import { constantRoutes as commonConstantRoutes } from "./common";
import { managementRoutes } from "./management";
import { officeRoutes } from "./office";
import { merchantRoutes } from "./merchant";
import config from "@/config";
export { dynamicRoutes } from "./common";

Vue.use(Router);

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
    office: 0                       // 平台 0 管理端 1 售票端
  }
 */

// 公共路由
export const constantRoutes = [...commonConstantRoutes];

if (config.platform === "management") {
  constantRoutes.push(...managementRoutes);
} else if (config.platform === "office") {
  constantRoutes.push(...officeRoutes);
} else if (config.platform === "merchant") {
  constantRoutes.push(...merchantRoutes);
}

//控制台有报路由相关错误，故解开注释
// 防止连续点击多次路由报错;
const routerPush = Router.prototype.push;
const routerReplace = Router.prototype.replace;
// push;
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((err) => err);
// };
// replace;
// Router.prototype.replace = function push(location) {
//   return routerReplace.call(this, location).catch((err) => err);
// };

const router = new Router({
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  const titleArr = [];
  if (config.name) {
    titleArr.push(config.name);
  }
  if (to.meta?.title) {
    titleArr.push(to.meta.title);
  }
  titleArr.push(process.env.VUE_APP_TITLE);
  document.title = titleArr.join("|");
  next();
});

export default router;
