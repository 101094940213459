import OfficeSelectDialog from "./index.vue";
import Vue from "vue";

const OfficeSelectDialogConstructor = Vue.extend(OfficeSelectDialog);

/**
 * 打开选择售票窗口对话框
 * @param {Vue} ctx 父组件
 * @returns {Promise}
 */
export function openOfficeSelectDialog(ctx) {
  console.log(this);
  const instance = new OfficeSelectDialogConstructor({
    el: document.createElement("div"),
    parent: ctx,
  });
  console.log(instance);
  // instance.$mount()
  document.body.appendChild(instance.$el);

  instance.open();

  return new Promise((resolve, reject) => {
    instance.$on("confirm", () => resolve());
    instance.$on("cancel", () => reject());
  });
}
