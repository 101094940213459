import Layout from "@/layout";

export const merchantRoutes = [
  {
    path: "",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: "IndexMerchant" */ "@/views/IndexMerchant"
          ),
        name: "Index",
        meta: { title: "首页", icon: "icon-shouye", affix: true },
      },
    ],
  },
];
