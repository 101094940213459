export default {
  namespaced: true,
  state: {
    office: null,
  },
  mutations: {
    setOffice(state, office) {
      state.office = office;
    },
  },
  actions: {},
};
