import Layout from "@/layout";

export const managementRoutes = [
  {
    path: "",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: "IndexManagement" */ "@/views/IndexManagement"
          ),
        name: "Index",
        meta: { title: "首页", icon: "icon-shouye", affix: true },
      },
    ],
  },
  {
    path: "/ticket/team",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index/:teamId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "Principal" */ "@/views/ticketing/team/principal"
          ),
        name: "Principal",
        meta: { title: "联系人", activeMenu: "/ticketing/team" },
      },
    ],
  },
  {
    path: "/ticket/operation/addTicket",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "add",
        component: () =>
          import(
            /* webpackChunkName: "AddTicket" */ "@/views/ticketing/operation/addTicket"
          ),
        name: "AddTicket",
        props: { type: "add" },
        meta: { title: "添加门票", activeMenu: "/ticket/operation" },
      },
      {
        path: "edit/:ticketId",
        component: () =>
          import(
            /* webpackChunkName: "EditTicket" */ "@/views/ticketing/operation/addTicket"
          ),
        name: "EditTicket",
        props: { type: "edit" },
        meta: { title: "修改门票", activeMenu: "/ticket/operation" },
      },
    ],
  },
  {
    path: "/ticket/member",
    component: Layout,
    hidden: true,
    children: [
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "MemberDetails" */ "@/views/common/MemberDetails/index"
          ),
        name: "MemberDetails",
        props: true,
        meta: {
          title: "会员详情",
          activeMenu: "/ticketMember/memberInformation",
        },
      },
      // {
      //   path: "/add/:id",
      //   component: () => import("@/views/ticketing/operation/addTicket"),
      //   name: "EditTicket",
      //   props: true,
      //   meta: { title: "新增会员", activeMenu: "/ticket/operation" },
      // },
    ],
  },
  {
    path: "/ticket/AddPrintTemplate",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "add",
        component: () =>
          import(
            /* webpackChunkName: "AddPrintTemplate" */ "@/views/ticketing/PrintTemplate/AddPrintTemplate/index"
          ),
        name: "AddPrintTemplate",
        params: {
          type: "add",
        },
        props: true,
        meta: {
          title: "打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "EditPrintTemplate" */ "@/views/ticketing/PrintTemplate/AddPrintTemplate/index"
          ),
        name: "EditPrintTemplate",
        params: {
          type: "edit",
        },
        props: (route) => ({
          type: "edit",
          id: route.params.id,
        }),
        meta: {
          title: "修改打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
    ],
  },
  {
    path: "/ticket/TemplateDesign",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "ticket/:id",
        component: () =>
          import(
            /* webpackChunkName: "TemplateDesign" */ "@/views/ticketing/PrintTemplate/TemplateDesign/index"
          ),
        name: "TemplateDesign",
        props: true,
        meta: {
          title: "设计门票打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "cashier/:id",
        component: () =>
          import(
            /* webpackChunkName: "CashierTemplateDesign" */ "@/views/ticketing/PrintTemplate/CashierTemplateDesign/index"
          ),
        name: "CashierTemplateDesign",
        props: true,
        meta: {
          title: "设计收银打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "member/:id",
        component: () =>
          import(
            /* webpackChunkName: "MemberTemplateDesign" */ "@/views/ticketing/PrintTemplate/MemberTemplateDesign/index"
          ),
        name: "MemberTemplateDesign",
        props: true,
        meta: {
          title: "设计会员打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "refundPayBack/:id",
        component: () =>
          import(
            /* webpackChunkName: "RefundPayBackTemplateDesign" */ "@/views/ticketing/PrintTemplate/RefundPayBackTemplateDesign/index"
          ),
        name: "RefundPayBackTemplateDesign",
        props: true,
        meta: {
          title: "设计退款补缴打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "checkTicket/:id",
        component: () =>
          import(
            /* webpackChunkName: "CheckTicketTemplateDesign" */ "@/views/ticketing/PrintTemplate/CheckTicketTemplateDesign/index"
          ),
        name: "CheckTicketTemplateDesign",
        props: true,
        meta: {
          title: "设计检票打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
      {
        path: "generalTicket/:id",
        component: () =>
          import(
            /* webpackChunkName: "GeneralTicketTemplateDesign" */ "@/views/ticketing/PrintTemplate/GeneralTicketTemplateDesign/index"
          ),
        name: "GeneralTicketTemplateDesign",
        props: true,
        meta: {
          title: "普通门票打印模板",
          activeMenu: "/system/printTemplate",
        },
      },
    ],
  },
  {
    path: "/ticket/ota_meituan",
    component: () =>
      import(/* webpackChunkName: "OtaLayout" */ "@/layout/OtaLayout"),
    hidden: true,
    children: [
      {
        path: "AccountAuthDetails",
        component: () =>
          import(
            /* webpackChunkName: "MeituanAccountAuthDetails" */ "@/views/ticketing/ota/AccountAuthDetails/index"
          ),
        name: "MeituanAccountAuthDetails",
        props: true,
        meta: {
          title: "账户授权详情",
        },
      },
      {
        path: "TicketAssociationSettings",
        component: () =>
          import(
            /* webpackChunkName: "MeituanTicketAssociationSettings" */ "@/views/ticketing/ota/TicketAssociationSettings/index"
          ),
        name: "MeituanTicketAssociationSettings",
        props: true,
        meta: {
          title: "票类关联设置",
        },
      },
      {
        path: "TakeTicketRecord",
        component: () =>
          import(
            /* webpackChunkName: "MeituanTakeTicketRecord" */ "@/views/ticketing/ota/TakeTicketRecord/index"
          ),
        name: "MeituanTakeTicketRecord",
        props: true,
        meta: {
          title: "取票记录",
        },
      },
      {
        path: "CheckTicketRecord",
        component: () =>
          import(
            /* webpackChunkName: "MeituanCheckTicketRecord" */ "@/views/ticketing/ota/CheckTicketRecord/index"
          ),
        name: "MeituanCheckTicketRecord",
        props: true,
        meta: {
          title: "核销记录",
        },
      },
    ],
  },
  {
    path: "/ticket/ota_douyin",
    component: () =>
      import(
        /* webpackChunkName: "DouyinOtaLayout" */ "@/layout/DouyinOtaLayout"
      ),
    hidden: true,
    children: [
      // {
      //   path: "AccountAuthDetails",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "DouyinAccountAuthDetails" */ "@/views/ticketing/ota/AccountAuthDetails/index"
      //     ),
      //   name: "DouyinAccountAuthDetails",
      //   props: true,
      //   meta: {
      //     title: "账户授权详情",
      //   },
      // },
      {
        path: "TicketAssociationSettings",
        component: () =>
          import(
            /* webpackChunkName: "DouyinTicketAssociationSettings" */ "@/views/ticketing/ota_douyin/TicketAssociationSettings/index"
          ),
        name: "DouyinTicketAssociationSettings",
        props: true,
        meta: {
          title: "票类关联设置",
        },
      },
      {
        path: "TakeTicketRecord",
        component: () =>
          import(
            /* webpackChunkName: "DouyinTakeTicketRecord" */ "@/views/ticketing/ota_douyin/TakeTicketRecord/index"
          ),
        name: "DouyinTakeTicketRecord",
        props: true,
        meta: {
          title: "取票记录",
        },
      },
      {
        path: "CheckTicketRecord",
        component: () =>
          import(
            /* webpackChunkName: "DouyinCheckTicketRecord" */ "@/views/ticketing/ota_douyin/CheckTicketRecord/index"
          ),
        name: "DouyinCheckTicketRecord",
        props: true,
        meta: {
          title: "核销记录",
        },
      },
    ],
  },
];
