import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import updateLocale from "dayjs/plugin/updateLocale";
import localeData from "dayjs/plugin/localeData";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import minMax from "dayjs/plugin/minMax";
import duration from "dayjs/plugin/duration";

dayjs.locale("zh-cn");
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(duration);
