import locale from "element-ui/lib/locale";
import InfiniteScroll from "element-ui/lib/infinite-scroll.js";
import Loading from "element-ui/lib/loading.js";
import MessageBox from "element-ui/lib/message-box.js";
import Message from "element-ui/lib/message.js";
import InputNumber from "element-ui/lib/input-number.js";
import Dialog from "element-ui/lib/dialog";
// import {
// locale,
// i18n,
// InfiniteScroll,
// Message,
// MessageBox,
// Loading,
// } from"element-ui";

const install = function (Vue, opts = {}) {
  // locale.use(opts.locale);
  locale.use(opts.locale);
  locale.i18n(opts.i18n);

  Vue.use(InfiniteScroll);
  Vue.use(Loading.directive);

  Vue.prototype.$ELEMENT = {
    size: opts.size || "",
    zIndex: opts.zIndex || 2000,
  };

  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = Message;

  // FEAT (潇)点击遮罩、按esc不关闭弹窗
  Dialog.props.closeOnClickModal.default = false;
  Dialog.props.closeOnPressEscape.default = false;

  // FEAT (甘)位数过大，需要限制位数
  InputNumber.props.max.default = 999999;
};

export default {
  version: "2.15.13",
  locale: locale.use,
  i18n: locale.i18n,
  install,
};
