import auth from "@/plugins/auth";
import router, { constantRoutes, dynamicRoutes } from "@/router";
import { getRouters } from "@/api/menu";
import Layout from "@/layout/index";
import IndexOfficeLayout from "@/layout/indexOffice";
import ParentView from "@/components/ParentView";
import InnerLink from "@/layout/components/InnerLink";
import config from "@/config";

/**
 * 根据path排除路由
 * @param {Array} routes
 * @param {Array} excludedPaths
 * @param {string} parentPath
 * @returns {Array}
 */
function excludedRoutesByPaths(routes, excludedPaths = [], parentPath = "") {
  return routes.filter((route) => {
    if (route.children?.length > 0) {
      route.children = excludedRoutesByPaths(
        route.children,
        excludedPaths,
        route.path,
      );
    }
    return excludedPaths.every((v) => {
      if (parentPath == "/") {
        return v !== `/${route.path}`;
      } else {
        return v !== `${parentPath}/${route.path}`;
      }
    });
  });
}

/**
 * 根据不同端过滤路由
 * @param {Array} routes 路由列表
 * @param {number} type 端类型
 * @returns {Array}
 */
function terminalsFilter(routes, type) {
  return routes.filter((v) => {
    if (v.children) {
      v.children = terminalsFilter(v.children, type);
    }
    return v.office == type;
  });
}

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit, rootState }) {
      return new Promise((resolve) => {
        // 向后端请求路由数据
        getRouters().then((res) => {
          const sdata = JSON.parse(JSON.stringify(res.data));
          const rdata = JSON.parse(JSON.stringify(res.data));
          let sidebarRoutes = filterAsyncRouter(sdata);
          // office 0 管理端 1 售票端 2 运营平台
          if (config.platform === "management") {
            sidebarRoutes = terminalsFilter(sidebarRoutes, 0);
          } else if (config.platform === "office") {
            sidebarRoutes = terminalsFilter(sidebarRoutes, 1);
          } else if (config.platform === "merchant") {
            sidebarRoutes = terminalsFilter(sidebarRoutes, 2);
          }

          /**
           * 游乐场版删除
           * 票务流程、检票时间方案、门票规则设置、会员活动
           */
          const amusementExcludedPaths = [
            "/ticketFlow",
            "/counterP/project",
            "/ticket/ticketType",
            "/ticketMember/memberConfig",
          ];

          // 游乐场版过滤路由
          if (rootState.user?.shop?.merchantType == "2") {
            sidebarRoutes = excludedRoutesByPaths(
              sidebarRoutes,
              amusementExcludedPaths,
            );
          }

          const rewriteRoutes = filterAsyncRouter(rdata, false, true);
          const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
          rewriteRoutes.push({ path: "*", redirect: "/404", hidden: true });
          asyncRoutes.forEach((v) => {
            router.addRoute(v);
          });
          commit("SET_ROUTES", rewriteRoutes);
          commit("SET_SIDEBAR_ROUTERS", constantRoutes.concat(sidebarRoutes));
          commit("SET_DEFAULT_ROUTES", sidebarRoutes);
          commit("SET_TOPBAR_ROUTES", sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    },
  },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        if (config.platform === "office") {
          route.component = IndexOfficeLayout;
        } else {
          route.component = Layout;
        }
      } else if (route.component === "ParentView") {
        route.component = ParentView;
      } else if (route.component === "InnerLink") {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  let children = [];
  childrenMap.forEach((el) => {
    if (el.children && el.children.length) {
      if (el.component === "ParentView" && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = [];
  routes.forEach((route) => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}

export const loadView = (view) => {
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views/${view}.vue`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () =>
      import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`);
  }
};

export default permission;
